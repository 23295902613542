import * as React from "react"
import { graphql } from "gatsby"
import { CaseStudy as CaseStudyType } from "../../types"
import {
  Box,
  Grid,
  Heading,
  HStack,
  Image,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react"
import Container from "../../components/container"
import SEO from "../../components/seo"

interface Props {
  data: {
    contentfulCaseStudy: CaseStudyType
  }
}

const CaseStudy: React.FC<Props> = ({ data }) => {
  const { images, client, summary, title, technologies } =
    data.contentfulCaseStudy

  return (
    <Box>
      <SEO title="Nomad Radio" />
      <Image
        height={["70vh", "100vh"]}
        width="100vw"
        src={images[0].file.url}
        objectFit="cover"
      />
      <Container>
        <Stack align="center" py={[8, 24]}>
          <Grid
            templateColumns={["1fr", "1fr 1fr"]}
            gridGap={[4, 12]}
            fontSize="xl"
          >
            <Stack>
              <Heading size="3xl">{title}</Heading>
              <HStack color="darkgray">
                <Text>Client:</Text>
                <Text>{client}</Text>
              </HStack>
            </Stack>
            <Stack>
              <Text>{summary.summary}</Text>
              <HStack color="darkgray">
                <Text>{technologies}</Text>
              </HStack>
            </Stack>
          </Grid>
        </Stack>
        <Stack spacing={[8, 16]}>
          {images.slice(1).map(image => (
            <img src={image.file.url} />
          ))}
        </Stack>
      </Container>
    </Box>
  )
}

export default CaseStudy

export const query = graphql`
  query CaseStudyQuery($slug: String!) {
    contentfulCaseStudy(slug: { eq: $slug }) {
      technologies
      title
      slug
      client
      summary {
        summary
      }
      images {
        file {
          url
        }
      }
    }
  }
`
